/* -----------------------------
*
** File:	Bootsrap overrides - _custom.scss
** Author: 	Robert Katke
*
*  ------------------------------------------------------------------------
** Write custom styles for template to this file to override default values
** without modify source files.
*  ------------------------------------------------------------------------
*
* 1. Global Styles
* 2. Header Styles
* 3. Main Content Styles
* 4. Footer Styles	
* 5. Navigation Styles
* 6. Sidebar Styles
* 7. Form Styles
* 8. Searchbox Styles
* 9. Breadcrumb Styles
* 10. Button Styles
* 11. Pages Styles
* 12. Infobar Styles
* 13. Tooltips Styles
* 14. Tables Styles
* 15. Icon Styles
* 16. Panel Tabs Styles
* 17. Cutsom Modal Styles
* 18. Images Styles
* 19. Alert Styles
* 20. Headline Styles
* 21. List Styles
*
----------------------------- */


/* 1. Global Styles
----------------------------- */

* {
	outline: none;
}

body.debug {
	// padding-top: 35px;

	&:before {
		content: 'xs-breakpoint';
		position: fixed;
		top: 0;
		left: 0;
		background: $brand-primary;
		color: $white;
		display: block;
		padding: 5px;
		z-index:  9999;

		@media (min-width: 36em){
			// sm-breakpoint == 576px
			content: 'sm-breakpoint';
		}

		@media (min-width: 48em){
			// md-breakpoint == 768px
			content: 'md-breakpoint';
		}

		@media (min-width: 62em){
			// lg-breakpoint == 992px
			content: 'lg-breakpoint';
		}

		@media (min-width: 75em){
			// xl-breakpoint == 1200px
			content: 'xl-breakpoint';
		}
	}
}
strong {
	font-weight: 600;
}
.no-margin {
	margin: 0 !important;
}
.margin-top {
	margin-top: $spacer;
	display: block;

	&.margin-half {
		margin-top: $spacer / 2;
	}
}
.margin-left {
	margin-left: $spacer !important;

	&.margin-half {
		margin-left: $spacer / 2 !important;
	}
}
.hidden {
	display: none;
}
.small-margin {
	margin: .5rem 0;
}
.container-fluid {
	width: 100%;
	max-width: 1240px;
}
.sm-show {
	display: none;

	@media (min-width: 36em){
		// sm-breakpoint == 576px
		display: block;
	}
}
.md-show {
	display: none;

	@media (min-width: 48em){
		// md-breakpoint == 768px
		display: block;
	}
}
.lg-show {
	display: none;

	@media (min-width: 62em){
		// lg-breakpoint == 992px
		display: block;
	}
}
.xl-show {
	display: none;

	@media (min-width: 75em){
		// xl-breakpoint == 1200px
		display: block;
	}
}

@media (min-width: 75em){
	// xl-breakpoint == 1200px
	.col-xl-special {
		max-width: 20%;
	}
}

.bold {
	font-weight: bold;
}

hr {
	&.half {
		margin: $spacer/2 0;
	}

	&:first-child {
		margin-top: 2rem;
	}
}

.p-static {
	position: static;
}


/* 2. Header Styles
----------------------------- */

header.main-header {
	width: 100%;
	border-bottom: 3px solid $brand-primary;
	
	.user-area,
	.branding,
	.navi-add {
		position: relative;
		z-index: 101;
	}

	.user-area {
		background: $brand-primary;
		color: $white;
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
		display: flex;
		margin-bottom: $main-margin;

		> div {
			flex: 0 0 50%;
			text-align: center;
			padding: 10px;

			.btn-link {
				color: white;
				cursor: pointer;
			}

			i {
				margin-right: 10px;
			}
		}
	}

	.branding {
		max-width: 320px;
		display: block;
		margin: 0 auto $main-margin auto;
	}

	@media (min-width: 48em){
		// md-breakpoint == 768px
		.branding {
			margin: $main-margin 0 $main-margin 0;
		}
	}
}

/* 3. Main Content Styles
----------------------------- */

main {
	margin: 50px 0;
}

/* 4. Footer Styles
----------------------------- */

footer.main-footer {
	width: 100%;
	background: $white;

	.ornament {
		background: $gray-lighter;
		height: 70px;
		margin-bottom: $main-margin;

		.white-bg {
			background: white;
			margin-top: 15px;
			padding: 0 10px;
			height: 40px;
			display: flex;
			justify-content: space-between;


			.gray-branding {
				height: 80%;
				width: auto;
				align-self: center;
			}
		}
	}

	ul.sitemap {
		padding: 0;

		li {
			display: block;
			padding: 5px 0;
			border-top: 1px solid $gray-lighter;

			&:first-child {
				border-top: none;
			}

			a {
				color: $gray-dark;
			}
		}
	}

	.secondary-footer {
		background: $brand-primary;
		color: $white;
		padding: 10px 0;
		text-align: center; 
		margin-top: $main-margin;
	}

	@media (min-width: 48em){
		// md-breakpoint == 768px
		.secondary-footer {
			text-align: left;
		}
	}

	@media (min-width: 75em){
		// xl-breakpoint == 1200px
		ul.sitemap {
			li {
				padding: 10px 0;
			}
		}

		.secondary-footer {
			font-size: .8em;

			.navi-add {
				margin-bottom: 0;
			}
		}
	}
}

/* 5. Navigation Styles
----------------------------- */

.main-nav {
	display: flex;
	justify-content: space-between;
	padding: 0;

	li {
		display: inline-block;
	}
}

.navi-add {
	padding: 0;

	li {
		display: block;

		a {
			footer.main-footer & {
				color: $white;
			}
		}
	}

	header.main-header & {
		li {
			a {
				color: $gray;
				font-size: .8rem;
			}
		}
	}

	@media (min-width: 36em){
		// sm-breakpoint == 576px
		li {
			display: inline-block;
			padding: 0 10px;

			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}

			a {
				footer.main-footer & {
					color: $white;
				}
			}
		}
	}
	@media (min-width: 75em){
		// xl-breakpoint == 1200px
		header.main-header & {
			margin-top: $main-margin / 2;
		}
	}
}

.navi-buttons {
	margin: $main-margin -15px;
	padding: 0;
	list-style: none;
	justify-content: center;

	li {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 15px;
		margin-bottom: 15px;

		a {
			background: $gray-dark;
			display: flex;
			justify-content: center;
			border-radius: $border-radius;
			height: 100%;
			overflow: hidden;
			color: $white;
			font-size: 1.38rem; //22px
			text-decoration: none;
			position: relative;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: all 1s ease;
				width: auto;
				max-width: none;
				height: 100%;
			}

			span {
				align-self: flex-end;
				display: block;
				width: 100%;
				background: rgba(0,0,0, .5);
				padding: $main-padding;
				position: relative;
			}
		}

		&:hover {
			img {
				transform: translate(-50%, -50%) scale(1.2);
			}
		}
	}

	@media (min-width: 48em){
		// md-breakpoint == 768px
		li {
			flex: 0 0 33%;
		}
	}

	@media (min-width: 62em){
		// lg-breakpoint == 992px
		li {
			flex: 0 0 25%;
		}
	}

	@media (min-width: 75em){
		// xl-breakpoint == 1200px
		li {
			flex: 0 0 20%;
		}
	}
}

/* 6. Sidebar Styles
----------------------------- */

.sidebar,
.content-box {
	background: $gray-lighter;
	border: 1px solid $gray-light;
	
	border-radius: $border-radius;

	.section {
		border-top: 1px solid $gray-light;
		padding: 35px 30px;

		&:first-child {
			border-top: 0;
		}

		&.section-filter {
			padding: 35px 0;

			> *:not(.filter-list) {
				padding: 0 30px;
			}

			.filter-list {
				padding: 30px 0;
				
				> ul {
					> li {
						padding: 0;

						> a:after {
							display: none !important;
						}
						a {
							display: block;
							padding: 10px 30px;						
						}

						ul {
							padding: 0;
							display: none;

							li {
								margin-left: 2px;
							}

							li {
								border-top: none;
								padding: 0;

								a {
									position: relative;
									padding: 5px 30px 5px 35px;
									display: block;
								}
							}
						}
					}
				}
				ul {

					li {
						border-left: 2px solid transparent;

						a {
							padding-left: 35px;
							position: relative;

							&:after {
								content: '';
								width: calc(100% - 65px);
								height: 1px;
								left: 30px;
								top: 0;
								background: $gray-light;
								position: absolute;
								display: block;
							}
						}
						
						&.active > ul {
							display: block;
						}
						&.has-sub {
							> a {

								&:before {
									display:inline-block;
									font:normal normal normal 14px/1 FontAwesome;
									font-size:inherit;
									text-rendering:auto;
									-webkit-font-smoothing:antialiased;
									-moz-osx-font-smoothing:grayscale;
									content: '\f107';
									position: absolute;
									left: 15px;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}

						&.active {
							border-left-color: $green;
							> a:before {
								transform: rotate(180deg) translateY(40%);
							}
						}
					}
				}
			}
		}
	}

	h2 {
		color: $brand-primary;
		margin-bottom: 1.5rem;
	}

	ul {

		&:last-child {
			margin-bottom: 0;
		}

		li {
			padding: 10px 0;
			display: block;
			border-top: 1px solid $gray;

			&:first-child {
				border-top: none;
			}
			&:last-child {
				padding-bottom: 0;
			}

			a {
				color: $gray-dark;
			}
			&:hover > a,
			&.active > a {
				color: $brand-primary;
			}
		}
	}
}

.content-box {
	margin-bottom: $spacer / 2;
}

/* 7. Form Styles
----------------------------- */

form {
	width: 100%;

	::-webkit-input-placeholder {
		color: $gray;
		font-weight: 300;
	}
	::-moz-placeholder {
		color: $gray;
	    font-weight: 300;
	}
	:-ms-input-placeholder {
		color: $gray;
	    font-weight: 300;
	}
	:-moz-placeholder {
		color: $gray;
	    font-weight: 300;
	}

	&.copy-list {
		margin-top: $spacer / 2;
		padding-top: $spacer / 2;
		border-top: 1px solid $gray-light;
		input {
			width: 100%;
			margin-bottom: $spacer / 4;
		}


	}
	
	.form-group {
		margin-bottom: $spacer / 3;

		&:last-child {
			margin-bottom: 10px;
		}
	}

	select {
		font-weight: 300;
		color: $gray;
		width: 100%;

		&.custom-select {
			background: none;
			padding-right: 0;
		}
	}

	.select-container {
		position: relative;

		&:after {
			content: '\f078';
			font-family: 'FontAwesome';
			position: absolute;
			color: $gray;
			font-size: .7rem;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	input, input.form-control, select, textarea {
		border-radius: $border-radius;
		border: 1px solid $gray;
		min-height: 40px;
		color: $gray-dark;
		font-weight: 300;
		width: 100%;
		padding: {
			right: 10px;
			left: 10px;
		}
	}

	input.form-control:focus {
		box-shadow: 0 0 5px rgba($brand-primary, .3);
		border-color: rgba($brand-primary, .3);
		color: $gray-dark;
	}

	.form-text {
		color: $gray-dark;
	}

	button[type="submit"]:not(.btn-search) {
		min-height: 40px;

		i {
			font-size: 0.75rem; // 12px
		}
	}

	.select2-container--default {
		.select2-selection--single {
			min-height: 40px;

			.select2-selection__rendered {
				min-height: 40px;
				line-height: 40px;
			}
			.select2-selection__arrow {
				height: 40px;
			}
		}
	}

	.input-group {
		input {
			border-right: none;
			border: {
				top-right-radius: 0;
				bottom-right-radius: 0;
			}
		}
		.input-group-btn {
			max-height: 40px; 
			border: 1px solid;
			position: relative;
			padding: {
				right: $spacer / 5;
				left: $spacer / 5;
			}
			border: {
				color: $gray;
				radius: $border-radius;
				top-left-radius: 0;
				bottom-left-radius: 0;
				left: none;
			}

			&:before {
				content: '';
				width: 1px;
				height: 60%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background: $gray;
			}

			i {
				color: $gray-dark;
			}

			&:hover {
				i {
					color: $black;
				}
			}
		}

		&.in-stock {
			input {
				border-color: $brand-primary;
				color: $brand-primary;
				pointer-events: none;
			}
			.input-group-addon {
				border-color: $brand-primary;
				background: $brand-primary;
				color: $white;
			}
		}
		&.not-in-stock {
			input {
				border-color: $brand-danger;
				color: $brand-danger;
				font-style: italic;
				pointer-events: none;
			}
			.input-group-addon {
				border-color: $brand-danger;
				background: $brand-danger;
				color: $white;
			}
		}
		&.available-soon-stock {
			input {
				border-color: $brand-warning;
				color: $brand-warning;
				font-style: italic;
				pointer-events: none;
			}
			.input-group-addon {
				border-color: $brand-warning;
				background: $brand-warning;
				color: $white;
			}
		}
	}
}

/* 8. Searchbox Styles
----------------------------- */

form.search-container {
	position: relative;
	height: 160px;
	margin-bottom: $main-margin;
	width: auto;

	@media (min-width: 48em){
		// md-breakpoint == 768px
		height: 60px;
	}

	::-webkit-input-placeholder {
		color: $gray-dark;
		font-weight: 300;
	}
	::-moz-placeholder {
		color: $gray-dark;
	    font-weight: 300;
	}
	:-ms-input-placeholder {
		color: $gray-dark;
	    font-weight: 300;
	}
	:-moz-placeholder {
		color: $gray-dark;
	    font-weight: 300;
	}

	.input-fake {
		border: 1px solid $gray;
		border-radius: $border-radius;
		z-index: 101;
		position: relative;
		background: white;
		transition: all .4s ease;
		position: absolute;
		width: calc(100% - 30px);

		&.open {
			box-shadow: 0 5px 20px rgba($brand-primary, .2);
			border-color: rgba($brand-primary, .3);

			.search-results {
				height: auto;
				max-height: 2000px;
			}
		}
	}
	label {
		display: none;
	}

	input {
		padding: {
			right: 30px;
			left: 30px;
		}
	}

	input,
	select {
		min-height: 60px;
		border: none;
		border-bottom: 1px solid $gray-light;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		@media (min-width: 48em){
			// md-breakpoint == 768px
			border: none;
		}
	}

	.border-left {
		position: relative;

		&:before {
			content: '';
			width: 1px;
			height: 60%;
			background: $gray-light;
			display: block;
			position: absolute;
			left: 0;
			top: 20%
		}
	}

	.custom-select-search {
		position: relative;
		cursor: pointer;
	}

	.btn-search {
		background: none;
		width: 100%;
		margin-left: -7px;
		cursor: pointer;
		min-height: 60px;
		padding: {
			right: 0;
			left: 0;
		}

		i {
			color: $gray;
			margin-left: 0;
		}
	}

	.select2 {
		height: 100%;

		.select2-selection {
			border: none;
			padding-left: 30px;

			.select2-selection__rendered {
				color: $gray ;
				line-height: 3.88rem; //62px
			}

			.select2-selection__arrow {
				display: none;
			}

			@media (min-width: 48em){
				// md-breakpoint == 768px
				padding-left: 0;
			}
		}
		
	}
}

.search-results {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height .5s ease;

	.inner-padding {
		padding: 30px 15px;
		width: 100%;
	}

	&:before {
		content: '';
		width: calc(100% - 60px);
		height: 1px;
		background: $gray-light;
		top: 0;
		left: 30px;
		display: block;
		position: absolute;
	}

	.result {
		li {
			border-radius: 0;
		}
	}
}

.search-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba($white, .9);
	top: 0;
	left: 0;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease;

	&.open {
		opacity: 1;
		visibility: visible;
	}
}

/* 9. Breadcrumb Styles
----------------------------- */

.breadcrumb-container {
	background: $gray-lighter;
	border-bottom: 1px solid rgba($gray-light, .7);

	.back-link {
		line-height: 45px;
		float: left;
		display: inline-block;
		margin-right: $spacer / 2;
		padding-right: $spacer / 2;
		position: relative;

		&:after {
			content: '';
			width: 1px;
			height: 60%;
			background: $gray;
			position: absolute;
			right: 0;
			top: 50%;
			display: block;
			transform: translateY(-50%);
		}
	}

	.breadcrumb {
		background: transparent;
		border-radius: 0;
		font-size: 1rem;
		padding: 0;
		margin: 0;
		overflow: hidden;
		height: 45px;
		
		li {
			padding: 0 30px;
			line-height: 2.82rem; //45px
			position: relative;

			&:first-child {
				padding-left: 0;
			}

			&:before {
				content: '';
				height: 50px;
				width: 50px;
				background: red;
				position: absolute;
				top: 50%;
				right: 0;
				transform-origin: 50% 0;
				transform: rotate(45deg) translateY(-50%);
				background: linear-gradient(45deg, $gray-lighter 40%, $white 100%);
				box-shadow: 1px -1px 1px 0 rgba($black, .1), 2px -2px 4px 0 rgba($gray-lighter, .05);
			}

			a {
				color: $gray-dark;
				position: relative;

				&:hover {
					text-decoration: none;
					color: $gray;
				}
			}

			span {
				position: relative;
				color: $gray-dark;
			}
		}
		li.home {
			a {
				color: $brand-primary;
				font-size: 2rem;
			}
		}
	}
}

/* 10. Button Styles
----------------------------- */

.btn,
button {
	cursor: pointer;
	position: relative;
	background: $gray-light;

	&:last-child {
		i {
			margin-right: 0 !important;
		}
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}

	&:hover {
		background: darken($gray-light, 10%);
	}

	i {
		&:first-child {
			margin-right: 10px;
		} 	
		&:last-child {
			margin-left: 10px;
		}
	}

	&.close {
		&:hover {
			background: transparent;
			color: $gray;
		}
	}

	&.reset {
		border: none;
		background: none;
		padding: 0;
		margin: 0;
		color: $gray-dark;
	}

	&.reset-green {
		color: $brand-primary;

		&:hover {
			color: darken($brand-primary, 5%);
		}
	}

	&.btn-green,
	&.btn-primary {
		background: $brand-primary;
		color: $white;

		&:hover {
			background: darken($brand-primary, 5%);
		}
	}
	&.btn-cancel {
		background: $red;
		color: $white;
	}

	&.small-icon {
		i {
			font-size: 0.75rem; //12px
		}
	}

	&.open-copy-list {
		i {
			margin-left: 10px;
		}
	}

	&.disabled {
		background: $gray;
		border-color: $gray;

		&:hover {
			background: $gray;
		}
	}

	&.shopping-list {
		background: $gray-lighter;
		border: 1px solid $gray-light;
		color: $brand-primary;

		&:hover {
			background: $gray-lightest;
		}
	}

	&.add-to-cart,
	&.shopping-list {
		width: 100%;
		font-size: 1.25rem; //20px
		padding: 1rem;
		margin-bottom: $spacer / 4;

		i {
			font-size: 1.25rem !important; 
		}
	}

	&.btn-lighter {
		background: $gray-lighter
	}
}

/* 11. Pages Styles
----------------------------- */

.product-detail-container {
	.productimage-container {
		border: 1px solid $gray-light;
		border-radius: $border-radius;
		overflow: hidden;
		margin-bottom: 1rem;
		
		img {
			transition: all .3s ease;
			width: 100%;
			height: auto;
		}

		&:hover {
			img {
				transform: scale(1.1);
			}
		}

	}
	.productimage-matrix {

		.productimage-container {
			margin-left: 1rem;

			&:first-child {
				margin-left: 0;
			}
		}
		img {
			width: 100%;
		}
	}
	.brand {
		margin-bottom: 40px; 
	}

	.add-to-cart {
		.price {
			margin-bottom: $main-margin;

			.old-price {
				text-decoration: line-through;
				font-size: 1.2rem;
			}

			.badge {
				font-size: 1rem;
			}

			span.big-price {
				font-size: 1.62rem; //26px
				color: $black;
			}
		}
		.fake-input {
			margin-bottom: $main-margin;
		}
	}

	.item {
		margin: $spacer / 2 0;
	}

	.badge {
		margin: 0 5px;
	}
}

.item {
	&.item-color {
		.change-color {
			width: 40px;
			height: 40px;
			border: 2px solid white;
			border-radius: $border-radius;
			overflow: hidden;
		}
	}
	&.item-size {
		.change-size {
			padding: 5px 10px;
			border-radius: $border-radius;
			border: 1px solid $gray;
			margin-bottom: 10px;

			&.active {
				border: 1px solid $brand-primary;
				box-shadow: 0 0 0 1px $brand-primary;
			}

			&:hover {
				border: 1px solid $brand-primary;
			}
		}
	}

	.change-list {
		margin: 10px 0;

		li.change-item {
			cursor: pointer;
			user-select: none;

			&.disabled {
				cursor: not-allowed;
				background: $gray-light;
				border-color: $gray-light;
				pointer-events: none;
			}
		}

		li.active {
			box-shadow: 0 0 0 2px $brand-primary;
		}
	}
}

.products-container {
	.product {
		margin-bottom: $spacer / 2;

		.price {
			border-top: 1px solid $gray-light;
			margin: $spacer / 2 0;
			padding-top: $spacer / 2;

			b {
				color: $black;
			}

			&.special-price {
				.old-price {
					color: $red;
					font-weight: bold;
					font-size: 0.75rem; //12px
					text-decoration: line-through;
				}
				.special-price {
					color: $brand-primary;
				}
			}
		}
		.product-link {
			color: $gray-dark;
		}
	}
	.inner-product {
		border: 1px solid $gray-light;
		border-radius: $border-radius;
		padding: 0 30px 30px 30px;
		height: 100%;
	}
	form.to-cart {
		input {
			width: 70px;
			text-align: center;
		}
		button {
			padding: 0 22px;
		}
	}
	.content.horizontal-switch {
		.product {
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
			margin-bottom: $spacer / 4;
			
			.inner-product {
				border: none;
				border-top: 1px solid $gray-light;
				padding: $spacer / 4 0 0 0;
				align-items: center;
			}

			&:first-child {
				.inner-product {
					border: none;
				}
			}

			a.product-link {
				margin-right: auto;
				display: flex;
				align-items: center;

				img {
					width: 80px;
					height: auto;
					margin-right: $spacer / 2;
				}
			}

			.price {
				border: none;
				border-right: 1px solid $gray-light;
				margin: 0 $spacer / 2 0 $spacer * 2;
				padding: 0 $spacer/ 2 0 0;

				span {
					white-space: nowrap;
					font-size: .8em;
				}
			}
		}
		form.to-cart {
			input {
				width: 70px;
				text-align: center;
				height: 40px;
				margin-right: $spacer / 2;
			}
			button {
				padding: 0 22px;
				height: 40px;
			}
		}
	}
}

/* 12. Infobar Styles
----------------------------- */

.info-bar {

	&.top {
		border-bottom: 1px solid $gray-light;
		margin-bottom: $spacer;
		padding-bottom: 10px;

		&.small-margin {
			margin-bottom: $spacer / 4;
		}
	}
	&.bottom {
		border-top: 1px solid $gray-light;
		margin-top: $spacer;
		padding-top: 10px;

		&.small-margin {
			margin-top: $spacer / 4;
		}
	}

	span.label {
		display: inline-block;
		float: left;
		margin-right: $spacer / 2;
	}

	.switcher {
		padding-left: $spacer / 4;
		margin-left: $spacer / 4;
		border-left: 1px solid $gray-light;
	}
}

/* 13. Tooltips Styles
----------------------------- */

.tooltip {
	.tooltip-inner {
		background: $gray-dark;

		&:before {
			border-top-color: $gray-dark !important;
		}
	}
}

/* 14. Table Styles
----------------------------- */

table.table {
	td:first-child,
	th:first-child {
		padding-left: 0;
	}
	td:last-child,
	th:last-child {
		padding-right: 0;
	}
	&.no-border-top {
		thead {
			th {
				border-top: none;
			}
		}
	}
	&.table-custom-responsive {
		thead {
			display: none;
		}
		td {
			display: block;
			
			&[data-name] {
				&:before {
					content: attr(data-name) ": ";
					display: block;
					position: relative;
					font-weight: bold;
				}
			}
			
		}
	}
	@media (min-width: 48em){
		// md-breakpoint == 768px
		&.table-custom-responsive {
			thead {
				display: table-row-group;
			}
			td {
				display: table-cell;
				&[data-name] {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
table.shopping-list-detail {
	img.product-image {
		max-width: 80px;
		margin-left: 10px;
	}
	input[type="number"] {
		width: 70px;
	}
}

table.shopping-cart {
	input[type="number"] {
		width: 70px;
	}
	.price {
		min-width: 150px;
	}
	.input-group-addon.btn-light {
		i {
			color: $brand-primary;
			transition: color .3s ease;
		}

		&:hover {
			background: $brand-primary;
			i {
				color: $white;
			}
		}
	}
}

/* 15. Icon Styles
----------------------------- */
a {
	i {
		margin-left: $spacer / 5;

		&:first-child {
			margin-left: 0;
			margin-right: $spacer / 5;
		}
	}
}
i.small-icon {
	font-size: 0.75rem; //12px
}

/* 16. Panel Tabs Styles
----------------------------- */

.tab-pane {
	padding: $spacer $spacer/4;
}

.tab-content {
	border: 1px solid $gray-light;
	border-top: none;
	border-radius: $border-radius;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

/* 17. Custom Modal Styles
----------------------------- */

.modal-backdrop {
	background-color: white;

	&.show {
		opacity: .9;
	}
}

.modal {
	&.center {
		.modal-dialog {
			top: 50%;
		}
		&.show {
			.modal-dialog {
				transform: translate(0, -50%);
			}
		}
	}

	.modal-dialog {
		.modal-content {
			box-shadow: 0 5px 20px rgba($brand-primary, .2);
			border-color: rgba($brand-primary, .3);
		}
	}
}

/* 18. Images Styles
----------------------------- */

img {
	max-width: 100%;

	&.brand {
		max-width: 150px;
	}

	&.shopping-cart-thumb {
		max-width: 80px;
	}
}

/* 19. Alert Styles
----------------------------- */

.alert {
	text-align: center;

	&.alert-danger {
		background: $brand-danger;
		border-color: darken($brand-danger, 5%);
		color: $white;
	}
}

/* 20. Headline Styles
----------------------------- */

h2 {
	margin: 40px 0 20px 0;
}


/* 21. Main Nav Styles
----------------------------- */

.navi-main {
	ul.category-list {
		li {
			&.sub-closed {
				ul {
					display: none;
				}
			}
		}
		@media (min-width: 48em){
			// md-breakpoint == 768px
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			// justify-content: flex-start;
			position: relative;

			li {
				padding: 20px $main-margin / 2;
				align-items: baseline;
				// position: relative;

				&.open > a {
					position: relative;

					&:before {
						content: '';
						border: 10px solid darken($white, 5%);
						border-top-color: transparent;
						border-left-color: transparent;
						border-right-color: transparent;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: -23px;
						display: block;
					}
				}

				.mega-sub {
					background: darken($white, 5%);
					padding: 0 $main-padding*2;
					display: flex;
					position: absolute;
					height: auto;
					flex-wrap: wrap;
					z-index: 1;
					border: 1px solid $gray-light;
					border-top: 0;
					border-radius: $border-radius;
					border-top-right-radius: 0;
					border-top-left-radius: 0;

					li.open > a:before {
						border: 10px solid darken($white, 10%);
						border-top-color: transparent;
						border-left-color: transparent;
						border-right-color: transparent;
					}

					.mega-sub {
						background: darken($white, 10%);

						li.open > a:before {
							border: 10px solid darken($white, 15%);
							border-top-color: transparent;
							border-left-color: transparent;
							border-right-color: transparent;
						}

						.mega-sub {
							background: darken($white, 15%);
						}
					}
				}
			}
		}
	} 
	.absolute-container {
		position: absolute;
		background: $white;
		border-radius: $border-radius;
		z-index: 100;
		padding: $main-padding*2;
		border: 1px solid $gray-light;
		white-space: nowrap;
		margin-top: 15px;	
		width: calc(100% - 30px);
		opacity: 0;
		visibility: hidden;
		transition: all .4s ease;
		left: 15px;

		&:before {
			content: '';
			width: 22px;
			height: 22px;
			border: 1px solid $gray-light;
			border-bottom-color: transparent;
			border-right-color: transparent;
			position: absolute;
			top: -3px;
			left: 50%;
			transform: rotate(45deg) translateX(-50%);
			display: block;
			background: $white;
		}
		&:after {
			content: '';
			height: 20px;
			width: 100%;
			position: absolute;
			top: -20px;
			left: 0;
		}
	}

	@media (min-width: 48em){
		// md-breakpoint == 768px
		.absolute-container {
			padding-top: 0;
			padding-bottom: 0;

			&:before {
				top: -11px;
				left: 25px;
				transform: rotate(45deg);
			}
		}
	}
}

ul:hover .absolute-container {
	opacity: 1;
	visibility: visible;
}


